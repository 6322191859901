navbar{
   
}
.nav-container {
    display: flex;
    flex-direction: column;
    background-color: black;
    padding: 20px 5px;
    align-items: center;
  
}


.top-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 20px;
}

.logo {
    height: 60px;
}

.main-nav {
    flex-grow: 1; 
    max-width: 600px;
    padding: 12px;
    border: transparent;
    border-radius: 5px;
    font-size: 18px;
}
    .form-control:focus{
        border: 4px red solid;
    }

    .bottom-section {
        width: 100%;
        margin-top: 20px;
    }

.nav-links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
}

.nav-link {
    color: white;
    font-size: 20px;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
}

    .nav-link:hover {
       /* background-color: white;*/
        color: red;
    }

.navMenu-link {
    color: black;
    text-decoration: none;
    
}

    /*.navMenu-link:hover{
        color: white;
    }
*/
    .input-group-text {
        background-color: white;
    }

    .input-group:focus {
        border: 4px red solid;
    }

.arrowStyle {
    margin-left: 25%;
  
}


.nav-ContainerOverride {
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    background-color: #fff;
    width: 40%
}

    .nav-ContainerOverride:focus-within {
        border: 1px solid red !important;
        box-shadow: none !important;
        border-radius: 0.375rem !important;
    }

.nav-inputOverride {
    border: none;
    padding-left: 0px
}

    .nav-inputOverride:focus {
        border: 0px solid red !important;
        box-shadow: none !important;
        border-radius: 0.375rem !important;
    }

.nav-IconContainerOverride {
    background-color: white;
    border-right: 0;
    border: none;
}

.dropdown-menu {
    border-radius: 0px;
   
}

.dropdown-item-explore {
    background-color: #ff7474;
    padding-bottom: 15px;
    padding-top: 15px;
}

.dropdown-item-explore:hover {
    background-color: red;
    color: white;

}


.dropdown-item:focus {
    background-color: red;
}

.dropdown-item-explore:hover .navMenu-link {
    color: white;
}