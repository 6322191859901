.product-page {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    font-family: Arial, sans-serif;
    width: 100%;
}

.product-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px;
    margin: 0;
    gap: 20px;
}

.product-image {
    flex: 1 1 300px;
    text-align: center;
    margin-right: 20px;
}

.thumbnail-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 15px;
   
}

.thumbnail {
    width: 80px;
    height: 80px;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.2s ease, border 0.2s ease;
}

    .thumbnail:hover {
        transform: scale(1.1);
    }

    .thumbnail.selected {
        border: 2px solid red;
    }

.prodView-image-container {
    background-color: #d6d6d6;
    width: 100%;
    max-width: 650px;
    height: auto;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

    .prodView-image-container img {
        transition: transform 0.5s ease;
        max-width: 100%;
        height: auto;
        padding: 5%;
    }

.slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.main-image {
    width: 100%;
    height: auto;
    flex-shrink: 0;
}

.product-details {
    flex: 1 1 300px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

    .product-details h2 {
        font-size: 32px;
        margin-bottom: 15px;
        color: #333;
    }

    .product-details p {
        font-size: 18px;
        color: #555;
    }

.siren-label {
    font-size: 16px;
    margin-bottom: 10px;
    color: #333;
}

.siren-dropdown {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.quantity-control {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.decrement,
.increment {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

    .decrement:hover,
    .increment:hover {
        background-color: #0056b3;
    }

.quantity-input {
    width: 60px;
    text-align: center;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
}

.add-to-quotation {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 15px 30px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

    .add-to-quotation:hover {
        background-color: #218838;
    }

/* Responsive Adjustments */
@media (max-width: 1024px) {
    .prodView-image-container {
        max-width: 500px;
        padding: 15px;
    }

    .product-details h2 {
        font-size: 28px;
    }

    .product-details p {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .prodView-image-container {
        max-width: 350px;
    }

    .quantity-control {
        flex-direction: column;
        align-items: flex-start;
    }

    .quantity-input {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .prodView-image-container {
        max-width: 280px;
    }

    .add-to-quotation {
        font-size: 16px;
        padding: 10px 20px;
    }
}

.prod-info-titles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease-in-out; /* Optional: to add a background color transition when hovered */
}

    .prod-info-titles:hover {
        background-color: #f0f0f0; /* Optional: hover effect for the section title */
    }

.prod-info-content {
    overflow: hidden;
    transition: max-height 0.3s ease-in-out; /* Transition for sliding effect */
    max-height: 0; /* Initially hidden */
    padding-left: 10px;
    padding-right: 10px;
}

    .prod-info-content.open {
        max-height: 500px; /* Arbitrary large value to ensure content fits */
        padding-top: 10px;
        padding-bottom: 10px;
    }

.prod-info-titles span {
    font-size: 18px; /* Adjust for visibility */
}

.prod-info-content p,
.prod-info-content ul {
    margin: 0;
    padding: 0;
    color: #555;
}

.product-description-container {
    position: relative; 
    margin-top: 10px; 
    overflow: visible; 
}


.benefits-columns {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.bullet-list {
    list-style-type: disc;
    padding-left: 20px;
}

.left-column,
.right-column {
    flex: 1;
}

.documents-container {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    gap: 1%; /* Adjust gap between items */
}

.docs-Button {
    background-color: #ff7474;
    padding: 10px;
    text-align: left;
    font-size: large;
    transition: max-height 0.3s ease-in-out;
    position: relative;
    width: calc(50% - 1%);
    box-sizing: border-box;
    margin-bottom: 1%;
    color: black;
}

    .docs-Button a {
        color: black;
        text-decoration: none;
        display: block;
        margin-bottom: 10px;
    }

    .docs-Button:hover {
        background-color: red;
        transition: background 0.3s ease-in-out;
    }

        .docs-Button:hover {
            color: white;
        }

    .docs-Button .icon {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        color: black;
       
    }

    .docs-Button:hover .icon {
        color: white;
    }

.rotate-up {
    transform: rotate(0deg);
    transition: transform 0.3s ease;
    color: red;
}

.rotate-down {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
    color: green; /* Example: Different color for visibility */
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    
    z-index: 9999; /* Ensures it's on top of other content */
}