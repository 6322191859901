.InputGroupContainerOverride {
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    background-color: #fff;
    /*width: 10%*/
}

    .InputGroupContainerOverride:focus-within {
        border: 1px solid red !important;
        box-shadow: none !important;
        border-radius: 0.375rem !important;
    }

.InputGroupInputOverride {
    border: none;
    padding-left: 0px
}

    .InputGroupInputOverride:focus {
        border: 0px solid red !important;
        box-shadow: none !important;
        border-radius: 0.375rem !important;
    }

.InputGroupIconContainerOverride {
    background-color: white;
    border-right: 0;
    border: none;
}


.fullscreen-image {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
}

    .fullscreen-image img {
        width: 100%;
        height: 60%;
    }

.card {
    height: 100%;
}

.image-container {
    height: 150px; /* Set the desired height for the image container */
    display: contents;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.card-image {
    height: 100%; /* Set the desired height for the image */
    object-fit: contain;
}

.card-title {
    font-size: 16px; /* Adjust the font size as needed */
}

.card-text {
    font-size: 14px; /* Adjust the font size as needed */
}

.card-col {
    margin-bottom: 20px; /* Adjust the spacing as needed */
}



.filter-header {
    font-size: 18px;
    margin-bottom: 10px;
}

.centralize-cards {
    display: flex;
    justify-content: center;
}

.category-col {
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    text-align: left;
}

.category-list {
    padding: 10px;
    margin-top: 10px;
}

.category-option {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

    .category-option input[type="radio"] {
        margin-right: 5px;
    }

    .category-option label {
        font-size: 14px;
        cursor: pointer;
    }

        .category-option label:hover {
            text-decoration: underline;
        }


.image-container-ProdView {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh; /* Adjust the height to your preference */
}

.product-image-ProdView {
    max-width: 100%;
    max-height: 100%;
}



.product-description-container-floating {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 70vh;
}

    .product-description {
        background: red;
        color: white;
        border-radius: 10px;
        padding: 10px;
        white-space: pre-line;
    }


.button-section-prodview {
    margin-top: 10%
}

.product-description-container {
    transition: opacity 0.3s ease-in-out;
}

.fade-in {
    opacity: 1;
}

.fade-out {
    opacity: 0;
}


.bullet-list {
    list-style-type: disc;
    margin-left: 20px;
}

    .bullet-list li {
        margin-bottom: 5px;
    }


.image-container-ProdView-gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Adjust the number of columns as needed */
    gap: 10px; /* Adjust the gap between images as needed */
    margin-top: 25%;
}

.image-item-gallery {
    width: 100%; /* Adjust the width of each item as needed */
    border: 1px solid #babab8; /* Add a thin black border */
    cursor: pointer;
}

.product-image-ProdView-gallery {
    width: 100%; /* Adjust the width of each image as needed */
}

.image-item-gallery-prod {
    width: 100%; /* Adjust the width of each item as needed */
    border: 1px solid #babab8; /* Add a thin black border */
    cursor: zoom-in;
    border-radius: 20px
}

/* Common styles for all screen sizes */
.circle-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.icon-wrapper {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.menu-btn-selected {
    background-color: red;
    transition: background-color 0.5s ease;
}

.menu-btn-unselected {
    background-color: #808080;
    transition: background-color 0.5s ease;
}

.button-icon {
    font-size: 30px;
}

.button-text {
    margin-bottom: 10px;
}

prodView-button-list {
    display: flex !important;
}

/* Styles for screens with a max-width of 768px (small screens) */
@media (max-width: 768px) {
    .circle-button {
        flex-direction: column;
        margin-right: 10px
    }

    .icon-wrapper {
        width: 60px;
        height: 60px;
    }


    .button-text {
        margin-top: 5px;
        margin-bottom: 0;
    }

    prodView-button-list {
        display: flex !important;
    }
}

/* Styles for screens with a min-width of 1200px (full screens) */
@media (min-width: 1200px) {
    .button-container {
        flex-direction: column;
    }
}


.image-container-prodView-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.full-screen-image-prodView-modal {
    max-width: 100%;
    max-height: 100%;
}

.featured-products {
    text-align: center;
   /* width: 100%;*/
   padding-bottom: 20%;
}

.carousel {
    display: flex;
    align-items: center;
    position: relative;
}

.product-container {
    display: flex;
    overflow: hidden;
    width: 80%;
    justify-content: space-between; /* Space between products */
    gap: 20px; /* Add consistent spacing between items */
    margin: 0 auto;
}

.product-link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    border: 2px solid black;
    border-radius: 8px;
    overflow: hidden;
    height: 100%; /* Uniform height for links */
    flex: 1; /* Make all links take up equal space */
}

.product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    height: 350px; /* Set a fixed height for consistent sizing */
    box-sizing: border-box; /* Include padding in height calculation */
}

    .product img {
        width: 100%;
       /* height: auto;*/
        object-fit: cover;
     /*   flex-grow: 1; 
        max-height: 200px; */
    }

    .product h2 {
        margin-top: 10px;
        text-align: center;
        font-size: 1.2rem;
        color: #0073e6;
    }


.arrow {
    background: none;
    border: none;
    font-size: 2.5rem;
    cursor: pointer;
    user-select: none;
    position: static;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    margin-top: 30%
}

    .arrow.left {
        left: 0;
    }

    .arrow.right {
        right: 0;
    }


.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #000 #000 transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

    .loader::after,
    .loader::before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border: 3px solid;
        border-color: transparent transparent red red;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-sizing: border-box;
        animation: rotationBack 0.5s linear infinite;
        transform-origin: center center;
    }

    .loader::before {
        width: 32px;
        height: 32px;
        border-color: #000 #000 transparent transparent;
        animation: rotation 1.5s linear infinite;
    }

.loaderWhite {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #fff #fff transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

    .loaderWhite::after,
    .loaderWhite::before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border: 3px solid;
        border-color: transparent transparent red red;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-sizing: border-box;
        animation: rotationBack 0.5s linear infinite;
        transform-origin: center center;
    }

    .loaderWhite::before {
        width: 32px;
        height: 32px;
        border-color: #fff #fff transparent transparent;
        animation: rotation 1.5s linear infinite;
    }


.all-products-button {
  /*  width: 50px; 
    height: 50px;*/
    font-size: 16px;
    background-color: white;
    color: red;
    border: 2px solid red; 
    border-radius: 0; 
    cursor: pointer;
    display: inline-block;
    text-align: center;
    line-height: 46px;
    transition: background-color 0.3s, color 0.3s;
}

    .all-products-button:hover {
        background-color: red;
        color: white;
    }

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotationBack {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}